function UiComponentsPage() {
  return (
    <div className="page">
      <h1>Header 1</h1>
      <p>Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here. Test text here.</p>
      <h2>Header 2</h2>
      <p>Test text here. Test text here. Test text here. Test text here. Test text here. </p>
      <h3>Header 3</h3>
      <p>Test text here. Test text here. Test text here. Test text here. Test text here. </p>
      <h4>Header 4</h4>
      <p>Test text here. Test text here. Test text here. Test text here. Test text here. </p>
      <h5>Header 5</h5>
      <p>Test text here. Test text here. Test text here. Test text here. Test text here. </p>
      <p>
        Paragraph
        <a href='/#'>Link</a><br/>
      </p>
      <button>Button</button><br/>
      <input type='text' placeholder='Placeholder' /><br/>
      <input type='checkbox' /><br/>
      <input type='radio' /><br/>
    </div>
  );
}

export default UiComponentsPage;