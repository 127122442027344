import './under-construction.scss';
// import constructionLogo from '../../assets/images/fishbone-logo-construction.svg';

const UnderConstructionPage = () => {
  return (
    <div>
      <h1>Under Construction</h1>
      <p>This is the home of the Fishbone Digital software development group.</p>
      <p>Thanks for your patience while we work on the site!</p>
    </div>
  );
}
export default UnderConstructionPage;