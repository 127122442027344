import './404.scss';

const NotFoundPage: React.FC = () => {
  return (
    <div>
      <h1>We couldn't find what you're looking for, instead enjoy this<br/><span>404</span></h1>
    </div>
  );
}

export default NotFoundPage;