import React, { useState } from "react";
import "./footer.scss";

const SiteFooter = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="waves set3"></div>
        <div className="waves set2"></div>
        <div className="waves set1"></div>
      </footer>
    </React.Fragment>
  );
};

export default SiteFooter;
