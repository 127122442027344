import { Location, Route, Routes, useLocation } from 'react-router-dom';

import logoSvg_construction from './assets/images/logo-construction.svg';
import logoSvg_error from './assets/images/logo-error.svg';
import logoSvg_main from './assets/images/logo-default.svg';

import './App.scss';

import UnderConstructionPage from './pages/under-construction/under-construction';
import UiComponentsPage from './pages/ui-components/ui-components';
import NotFoundPage from './pages/error/404';
import HomePage from './pages/home/home';
import SiteHeader from './components/site/header/header';
import SiteFooter from './components/site/footer/footer';
import { LogoDisplayConfig } from './models/logo-display.config';

// test commit
//type AppProps = { appPropTest: string };
//type SiteProps = { sitePropTest: string };

function getStyledLogo(location: Location): LogoDisplayConfig {
  let imgSrc = '';
  let cssClass = 'logo-container';  
  switch(location.pathname) {
    case '/':
    case '/index':
      imgSrc = logoSvg_construction;
      cssClass += ' logo-construction'
      break;
    case '/home':
    case '/ui-test': 
      imgSrc = logoSvg_main;
      cssClass += ' logo-default';
      break;      
    default: 
      imgSrc = logoSvg_error;
      cssClass += ' logo-error';
  }
  const logoDisplay: LogoDisplayConfig = {
    imgSrc: imgSrc,
    cssClass: cssClass,
  }
  return logoDisplay;
}

const App = () => {  
  const loc = useLocation(); // NOTE: have to call useLocation from within a React component / FC (e.g. not in getLogo() function)
  const logo: LogoDisplayConfig = getStyledLogo(loc);  
  return (
    <div className='site'>
      <SiteHeader imgSrc={logo.imgSrc} cssClass={logo.cssClass} />
      <main>
        <Routes>
          <Route path='/' element={<UnderConstructionPage/>} />
          <Route path='/index' element={<UnderConstructionPage/>} />
          <Route path='/home' element={<HomePage/>} />
          <Route path='/ui-test' element={<UiComponentsPage/>} />
          <Route path='*' element={<NotFoundPage/>} />
        </Routes>
      </main>
      <SiteFooter />
    </div>
  );
}

export default App;

