import React, { useState } from "react";
import { LogoDisplayConfig } from "../../../models/logo-display.config";
import './header.scss';

const SiteHeader = ({ imgSrc, cssClass }: LogoDisplayConfig) => {
  return(
    <React.Fragment>  
      <header className="site-header">
        <div className={cssClass}>
          <img className="logo" src={imgSrc} alt="Fishbone Logo" aria-hidden="true" />
        </div>
        <div>
          <h1 className="title">
            <div className="fishbone">Fishbone</div>
            <div className="digital">digital</div>
          </h1>
        </div>
      </header>
    </React.Fragment>
  );
};

export default SiteHeader;
