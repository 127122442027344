import React, { useEffect, useState } from "react";
import { animated, useSpring } from "@react-spring/web";

import MenuCube from "../../components/menu-cube/menu-cube";
import "./home.scss";

const HomePage = () => {
  const [hover, setHover] = useState(true);

  // TODO - how to call animated with component instead of wrapping w/div?
  //const AnimatedMenuCube = animated(MenuCube);

  const stylesLoad = useSpring({
    from: {
      opacity: 0,
      scale: 0,
      y: "100%",
    },
    to: {
      opacity: 1,
      scale: 1,
      y: "0%",
    },
    config: {
      mass: 2,
      friction: 9.5,
      tension: 55,
    },
  });
  const stylesWait = useSpring({
    from: {
      y: "-1%",
    },
    to: [
      {
        y: "0.5%",
        scale: 1,
      },
      {
        y: "-0.5%",
        scale: 1,
      },
    ],
    config: {
      mass: 1,
      friction: 0,
      tension: 8.5,
    },
    loop: {
      reverse: true,
    },
  });
  const stylesHalt = useSpring({
    to: {
      y: "0%",
    },
  });
  const [props, set] = useSpring(() => ({
    from: {
      y: "-0.5%",
      //scale: 1,
    },
    to: {
      y: "0.5%",
      //scale: 1.5,
    },
    config: {
      mass: 1,
      friction: 10,
      tension: 40,
    },
  }));
  //
  // Callback for MenuCube click
  //
  function reportCubeClick(face: number) {
    const doHover = face === 0;
    setHover(doHover);
  }
  //
  // When component updates halt the animation if not showing menu face of cube
  //
  useEffect(() => {
    if (hover) {
      set(stylesWait);
    } else {
      set(stylesHalt);
    }
  });
  //
  // Containers for MenuCube that get loading and hover animations applied
  //
  return (
    <div className="container">
      <animated.div style={stylesLoad} className={"menu-container-load"}>
        <animated.div
          style={props}
          className={"menu-container-wait"}
        >
          <MenuCube onCubeClick={reportCubeClick} />
        </animated.div>
      </animated.div>
    </div>
  );
};
export default HomePage;
